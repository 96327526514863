/**
 * Returns a debounced function - As long as func is continued to be invoked, it will not 
 * be triggered. Func will be triggerrd after it stops being called for
 * `debug` milliseconds.
 * @param {*} func - function to be executed
 * @param {*} delay - delay before calling func, default = 500 milliseconds
 * @returns 
 */
const debounce = (func, delay = 500) => {
  let activeTimeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(activeTimeout);
      func(...args);
    };

    clearTimeout(activeTimeout);
    activeTimeout = setTimeout(later, delay);
  };
};

export default debounce;
